<template>
	<div id="app" style="position: relative">
		<button ref="butt" style="margin-top: 20%" @click="tootTootTrain">
			<img :src="cheekClapper" alt="Yeet thy fart" />
		</button>
		<div style="visibility: hidden" id="hide">
			<audio
				v-for="(i, count) in sharts"
				:key="count"
				preload="auto"
				:src="i.src"
				controls
			></audio>
		</div>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			cheekClapper: '/yeet_BUTTon1.webp',
			sharts: [],
		};
	},
	mounted() {
		for (let i = 1; i <= 10; i++) {
			this.sharts.push(new Audio(`/poots/fart${i}.wav`));
		}
		console.warn(
			`Hello welcome to fart yeeter. This is a stupid app that I made just for shits and giggles.
			I'm not responsible for any of the sounds you hear.
			It was a 20 minute project.
			I apologize for it's existence.
			©${new Date().getFullYear()} Marcello Bachechi
			`
		);
	},
	computed: {
		isMobile() {
			this.cheekClapper;
			return (
				navigator.userAgentData.mobile ||
				(window.innerWidth <= 800 && window.innerHeight <= 1000)
			);
		},
	},
	methods: {
		tootTootTrain() {
			this.$refs.butt.blur();
			const randomElement =
				this.sharts[Math.floor(Math.random() * this.sharts.length)];
			randomElement.play();
			randomElement.onended = () => {
				this.cheekClapper = '/yeet_BUTTon1.webp';
				randomElement.onended = undefined;
			};
			this.cheekClapper = '/yeet_BUTTon2.webp';
		},
		sorryForMaking() {
			new Audio('/WhoCreatedThisShit.wav').play();
			const so = confirm(
				"It was shitty of me to make this for y'all\n\n\nWanna see my stackoverflow?"
			);
			if (so) window.open('https://stackoverflow.com/users/2073738/');
		},
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #fff;
	margin-top: 60px;
}
body {
	background: #000;
	background-image: url('/booters.webp');
	background-repeat: repeat;
	background-size: cover;
}
img {
	width: 20vw;
	height: auto;
}
@media only screen and (max-width: 960px) {
	img {
		width: 80vw;
		height: auto;
	}
}

button {
	background: transparent;
	border: 0px;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#footer {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	bottom: 0px;
	left: 0px;
	padding: 5px;
}
</style>
